<template>
  <div id="chart" class="content-chart-global">
    <apexchart class="chart-component"  :type="sTypeChart" height="410" :width="screenWidth + 'px'" :options="chartOptions" :series="series">
    </apexchart>
  </div>
</template>
<script>
export default {
  props: {
    screenWidth:Number,
    sTypeChart: String,
    series: Array,
    chartOptions: Object
  },
  data() {
    return {
      aTrans: [],
    };
  },
  updated() {
  },
  beforeMount() {
  },
  methods: {
    getItem() {
      this.aTrans = [];
      if (this.aTransitionIn !== null) {
        this.aTrans.push(this.aTransitionIn);
      }
      if (this.aTransitionOut !== null) {
        this.aTrans.push(this.aTransitionOut);
      }
      DB.get(
        `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}/inventory/inout`,
        {
          params: {
            iPeriod: this.sShowFor,
            sInOut: this.aTrans.length > 1 ? null : this.aTrans[0],
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        }
      )
        .then((response) => {
          let sIn = this.aTransition.find((element) => element == "in");
          let sOut = this.aTransition.find((element) => element == "out");

          const newSeries = [
            {
              name: "Entrada",
              data: sIn !== undefined ? response.data.results.In : [],
            },
            {
              name: "Salidas",
              data: sOut !== undefined ? response.data.results.Out : [],
            },
          ];
          // this.series = newSeries;

          const newSeries4 = response.data.results.Periods;

          this.chartOptions.xaxis.categories = newSeries4;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    aTransition() {
      return this.$store.state.aTransition;
    },
    aTransitionIn() {
      return this.$store.state.aTransitionIn;
    },
    aTransitionOut() {
      return this.$store.state.aTransitionOut;
    },
    sShowFor() {
      return this.$store.state.sShowFor;
    },
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    refreshTable: function () {
      this.getItem();
    },
    aTransition() {
      this.getItem();
    },
    aTransitionIn() {
      this.getItem();
    },
    aTransitionOut() {
      this.getItem();
    },
    sShowFor() {
      this.getItem();
    },
    screenWidth(){
    }
  },
};
</script>
<style scoped>
.content-chart-global {
  /* width: 100%; */
}
</style>